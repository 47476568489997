<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col class="d-flex align-center justify-start" cols="8">
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table v-if="allInvoices"
                        dense
                        :no-data-text="$t('no-data-available')"
                        :loading-text="$t('loading')"
                        :loading="loading"
                        :search="appBarSearch"
                        :headers="headers"
                        hide-default-header
                        :items="allInvoices"
                        :items-per-page="-1"
                        group-by="clientName"
                        :height="pageHeight(120)"
                        ref="table"
                        :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
          >
            <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }">
              <td class="pointer white-bg-pointer table-border-bottom" @click="toggle" :colspan="2">
                <v-row class="align-center">
                  <v-col class="py-0 my-0" cols="3">
                    <div class="d-flex align-center">
                      <v-btn class="ml-n3" small icon :ref="group" :data-open="isOpen">
                        <v-icon  small v-if="isOpen" color="primary">mdi-arrow-down-drop-circle</v-icon>
                        <v-icon small v-else color="primary">mdi-arrow-right-drop-circle</v-icon>
                      </v-btn>
                      <span class="float-left font-weight-bold">{{ group }}</span>
                    </div>

                  </v-col>
                  <v-col class="py-0 my-0" cols="2">
                    <v-chip  class="mb-1 white--text" pill x-small color="primary">
                      {{ items.length }}
                    </v-chip>
                  </v-col>

                </v-row>

              </td>
            </template>
            <!-- <template v-slot:item.actions="{ item }">
              <v-icon @click="invoiceDetails(item)" style="cursor:pointer; margin-left:15px;" class="float-right" color="primary">mdi-pencil</v-icon>
            </template> -->
            <template v-slot:item="{ item, index }">
              <tbody>
                <td v-for="(header) in headers" :key="header.id" v-show="index === 0" style="width:400px" class="secondary text-left pl-4">
                  <span class="font-weight-medium font-size11 text-left">{{ header.text }}</span>
                </td>
              <!-- <tr @click="invoiceDetails(item)"> -->
              <tr>
                <td style="border-bottom: thin solid rgb(236, 234, 243)" class="text-left pl-4 font-size13 font-weight-medium">
                  {{ item.invoiceNumber }}
                </td>
                <td  style="border-bottom: thin solid rgb(236, 234, 243)" class="text-left pl-4 font-size13 font-weight-medium">
                  {{ item.invoiceDate }}
                </td>
                <td  style="border-bottom: thin solid rgb(236, 234, 243)" class="text-left pl-7 font-size13 font-weight-medium">
                    {{ formatCurrency(item.grandTotal) }}
                </td>
                <td  style="border-bottom: thin solid rgb(236, 234, 243)" class="text-left pl-9 font-size13 font-weight-medium">
                  {{ verifiersInvoiceStatus != null ? (verifiersInvoiceStatus.find(v => v.lowValue === item.status).shortDescription) : '' }}
                </td>
                <td style="border-bottom: thin solid rgb(236, 234, 243)" class=" font-weight-medium">
                  <v-icon  @click="invoiceDetails(item)" style="cursor:pointer; margin-left:15px; font-size:20px;" class="float-right mr-8" color="primary">mdi-pencil</v-icon>
                </td>
              </tr>
              </tbody>
            </template>
<!--            <template v-slot:[`footer.prepend`]>-->
<!--              <v-btn-->
<!--                  v-if="roleName === 'SYSTEM_ADMIN' || roleName === 'SYSTEM_USER'"-->
<!--                  class="primary2 text-color-white"-->
<!--                  label="Test"-->
<!--                  @click="addInvoice"-->
<!--              >-->
<!--                <img class="pt-1" height="20px" width="20px" :src="hospital_white" style="margin-right:3%; margin-bottom: 5%;"/>-->
<!--                <span style="text-transform: none;">{{ $t("add-new-invoice") }}</span>-->
<!--              </v-btn>-->
<!--            </template>-->
          </v-data-table>
        </v-card>
<!--        <InvoicesDialog-->
<!--            v-if="dialog"-->
<!--            :dialog="dialog"-->
<!--            :type="dialogType"-->
<!--            :selectedInvoice="selectedInvoice"-->
<!--            @false="invoiceUpdated"-->
<!--            @close="closeDialog"-->
<!--        />-->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { hospitalWhite } from '@/assets';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
// import InvoicesDialog from '@/views/dialogs/InvoicesDialog';

export default {
  // components: {
  //   InvoicesDialog,
  // },
  data: () => ({
    dialog: false,
    // dialogInvoice: false,
    dialogType: null,
    loading: false,
    togglers: {},
    search: '',
    headerClass: 'black_inner--text !important',
    expanded: [],
    hospital_white: hospitalWhite,
    selectedInvoice: null,
    headersTable: [
      { text: 'Organization', value: 'organization' },
      { text: 'Count', value: 'count' },
    ],
  }),
  computed: {
    ...mapState({
      allInvoices: (state) => state.invoices.allInvoices,
      verifiersFromRepo: (state) => state.verifiers.verifiers,
    }),
    ...mapGetters({
      roleName: 'authentication/getRole',
      delay: 'authentication/getDelay',
      appBarSearch: 'filterbar/getSearch',
      pageHeight: 'pageHeight',
    }),
    // testHeight () {
    //   switch (this.$vuetify.breakpoint.name) {
    //     case 'xs':
    //       return window.innerHeight - 320;
    //     case 'sm':
    //       return window.innerHeight - 300;
    //     case 'md':
    //       return window.innerHeight - 300;
    //     case 'lg':
    //       return window.innerHeight - 280;
    //     case 'xl':
    //       return window.innerHeight - 280;
    //     default:
    //       return 0;
    //   }
    // },
    headers () {
      var headers = [];
      headers = [
        {
          text: this.$t('header-invoice-number'),
          value: 'invoiceNumber',
          class: this.headerClass,
          width: 100,
          id: 1,
        },
        {
          text: this.$t('header-invoice-date'),
          value: 'invoiceDate',
          class: this.headerClass,
          id: 2,
        },
        {
          text: this.$t('header-invoice-grand-total'),
          value: 'grandTotal',
          class: this.headerClass,
          id: 3,
        },
        {
          text: this.$t('header-invoice-status'),
          value: 'status',
          class: this.headerClass,
          id: 4,
        },
        {
          value: 'clientName',
          class: this.headerClass,
          sortable: false,
          id: 5,
        },
      ];
      return headers;
    },
    verifiersInvoiceStatus () {
      if (this.verifiersFromRepo !== null) {
        const verifiersInvoiceStatus = this.verifiersFromRepo.filter(v => v.rvDomain === 'INVOICE_STATUS');
        return verifiersInvoiceStatus;
      } else {
        return [];
      }
    },
  },
  async mounted () {
    await this.getVerifiers();
    await this.getInvoices();
    // this.closeAll();
  },
  methods: {
    formatCurrency (value) {
      if (value == null || value === '') return ''; // Handle null or empty values gracefully
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    },
    toggleHeaders () {
      const table = this.$refs.table;
      if (table) {
        const keys = Object.keys(table.$vnode.componentInstance.openCache);
        keys.forEach((x) => {
          table.$vnode.componentInstance.openCache[x] = false;
        });
      }
    },
    async getVerifiers () {
      const header = { domainsList: 'INVOICE_STATUS' };
      await this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header);
    },
    async getInvoices () {
      this.toggleHeaders();
      this.loading = true;
      await this.$store.dispatch('invoices/getInvoices').then(() => {
        this.loading = false;
      });
      this.loading = false;
      this.toggleHeaders();
    },
    addInvoice () {
      this.dialog = true;
      this.dialogType = 'add';
      this.selectedInvoice = null;
    },
    invoiceDetails (item) {
      // call the drawer here  ( commit )
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', item);
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'invoices',
        item: item,
      });
      // this.dialog = true;
      // this.dialogType = 'edit';
      this.selectedInvoice = item;
    },
    closeDialog () {
      this.dialog = false;
      this.dialogType = null;
      this.selectedInvoice = null;
      this.getInvoices();
    },
    invoiceUpdated (res) {
      if (res.resFlag) {
        showSuccessAlert(this.$t('success'));
      } else {
        showErrorAlert(this.$t('failed'));
      }
      this.dialog = false;
      this.dialogType = null;
      this.selectedInvoice = null;
      this.getInvoices();
    },
  },
};
</script>
